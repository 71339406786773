// types
import type { NordicsHomeProps } from '../Home/home.types';

// components
import Layout from './layout';
import HeroImageGDDS from 'components/ContentElementsGdds/HeroImage/heroimage-gdds';
import { HeroTiles as HeroTilesGDDS } from 'components/ContentElementsGdds/Tiles/hero-tiles';
import { ContentArea } from 'components/ContentArea/ContentArea';

// utils
import { isEmpty } from 'utils/is-empty';

export function Home({
  metaData,
  contentAreas: { content },
  page: { heroTile, hero },
}: Readonly<NordicsHomeProps>) {
  return (
    <Layout metaData={metaData}>
      {!isEmpty(hero) && <HeroImageGDDS {...hero} isSeoConformCarousel />}
      {!isEmpty(heroTile) && <HeroTilesGDDS {...heroTile} />}
      <ContentArea content={content} contentType="content" />
    </Layout>
  );
}
